import React, {useContext, useState} from "react"
import "../css/vitaminsForm.css"
import Button from "../components/Button";
import {ArrowLeftIcon, ArrowRightIcon} from "../components/Icons";
import Tooltip from "../components/Tooltip";
import Checkmark from "../components/Checkmark";
import {NutricalRequestContext} from "./Form";

const Vitamin = ({name, checked, onChange}) => {

    return (
        <div className="flex hover:cursor-pointer hover:opacity-80 mb-4" onClick={onChange}>
            <Checkmark checked={checked}/>
            <div className="ml-2">{name}</div>
        </div>
    )
}

const VitaminsForm = ({onNext, onBack, hide, stepIndex}) => {

    const {nutricalRequest, setNutricalRequest} = useContext(NutricalRequestContext)
    const [checkAllVitamins, setCheckAllVitamins] = useState(false)
    const [checkAllMinerals, setCheckAllMinerals] = useState(false)

    if (hide) {
        return
    }


    let isValid = true;

    const handleChange = (name) => {
        setNutricalRequest((prevState) => ({
            ...prevState,
            nutrient_flag: {
                ...prevState.nutrient_flag,
                [name]: !prevState.nutrient_flag[name]
            }
        }))
    }

    const handleCheckAllVitamins = () => {
        setNutricalRequest(prevState => ({
            ...prevState,
            nutrient_flag: {
                ...prevState.nutrient_flag,
                "vitamin_a": !checkAllVitamins,
                "vitamin_d": !checkAllVitamins,
                "vitamin_e": !checkAllVitamins,
                "vitamin_k": !checkAllVitamins,
                "vitamin_c": !checkAllVitamins,
                "thiamin_b1": !checkAllVitamins,
                "riboflavin_b2": !checkAllVitamins,
                "niacin_b3": !checkAllVitamins,
                "vitamin_b6": !checkAllVitamins,
                "folate_b9": !checkAllVitamins,
                "vitamin_b12": !checkAllVitamins,
                "biotin_b7": !checkAllVitamins,
                "pantothenic_acid_b5": !checkAllVitamins,
            }
        }))

        setCheckAllVitamins(!checkAllVitamins)
    }

    const handleCheckAllMinerals = () => {
        setNutricalRequest(prevState => ({
            ...prevState,
            nutrient_flag: {
                ...prevState.nutrient_flag,
                "potassium": !checkAllMinerals,
                "chloride": !checkAllMinerals,
                "calcium": !checkAllMinerals,
                "phosphorus": !checkAllMinerals,
                "iron": !checkAllMinerals,
                "zinc": !checkAllMinerals,
                "copper": !checkAllMinerals,
                "magnesium": !checkAllMinerals,
                "manganese": !checkAllMinerals,
                "fluoride": !checkAllMinerals,
                "selenium": !checkAllMinerals,
                "chromium": !checkAllMinerals,
                "molybdenum": !checkAllMinerals,
                "iodine": !checkAllMinerals
            }
        }))

        setCheckAllMinerals(!checkAllMinerals)
    }

    return (
        <div className="VitaminsForm">
            <div className="section-id">
                <span>{stepIndex}</span>
                <span>Vitamini i minerali</span>
            </div>

            <div className="section mt-6">

                <div className="">Izaberite koje vitamine i minerale zelite da se prikazu u izvestaju</div>

                <Tooltip className="mt-8 mb-6" title="Vitamini">
                    Označite vitamine koje želite da prikažemo na deklaraciji, ili izaberite opciju "Označi sve"
                </Tooltip>

                <Vitamin name="Označi sve" checked={checkAllVitamins}
                         onChange={() => handleCheckAllVitamins()}/>

                <Vitamin name="Vitamin A" checked={nutricalRequest?.nutrient_flag?.vitamin_a}
                         onChange={() => handleChange("vitamin_a")}/>

                <Vitamin name="Vitamin D" checked={nutricalRequest?.nutrient_flag?.vitamin_d}
                         onChange={() => handleChange("vitamin_d")}/>

                <Vitamin name="Vitamin E" checked={nutricalRequest?.nutrient_flag?.vitamin_e}
                         onChange={() => handleChange("vitamin_e")}/>

                <Vitamin name="Vitamin K" checked={nutricalRequest?.nutrient_flag?.vitamin_k}
                         onChange={() => handleChange("vitamin_k")}/>

                <Vitamin name="Vitamin C" checked={nutricalRequest?.nutrient_flag?.vitamin_c}
                         onChange={() => handleChange("vitamin_c")}/>

                <Vitamin name="Tiamin" checked={nutricalRequest?.nutrient_flag?.thiamin_b1}
                         onChange={() => handleChange("thiamin_b1")}/>

                <Vitamin name="Riboflavin" checked={nutricalRequest?.nutrient_flag?.riboflavin_b2}
                         onChange={() => handleChange("riboflavin_b2")}/>

                <Vitamin name="Niacin" checked={nutricalRequest?.nutrient_flag?.niacin_b3}
                         onChange={() => handleChange("niacin_b3")}/>

                <Vitamin name="Vitamin B6" checked={nutricalRequest?.nutrient_flag?.vitamin_b6}
                         onChange={() => handleChange("vitamin_b6")}/>

                <Vitamin name="Folna kiselina" checked={nutricalRequest?.nutrient_flag?.folate_b9}
                         onChange={() => handleChange("folate_b9")}/>

                <Vitamin name="Vitamin B12" checked={nutricalRequest?.nutrient_flag?.vitamin_b12}
                         onChange={() => handleChange("vitamin_b12")}/>

                <Vitamin name="Biotin" checked={nutricalRequest?.nutrient_flag?.biotin_b7}
                         onChange={() => handleChange("biotin_b7")}/>

                <Vitamin name="Pantotenska kiselina" checked={nutricalRequest?.nutrient_flag?.pantothenic_acid_b5}
                         onChange={() => handleChange("pantothenic_acid_b5")}/>


                <Tooltip className="mt-8 mb-6" title="Minerali">
                    Označite minerale i mikroelemente koje želite da prikažemo na deklaraciji, ili izaberite opciju "Označi sve"
                </Tooltip>

                <Vitamin name="Označi sve" checked={checkAllMinerals}
                         onChange={() => handleCheckAllMinerals()}/>

                <Vitamin name="Kalijum" checked={nutricalRequest?.nutrient_flag?.potassium}
                         onChange={() => handleChange("potassium")}/>

                <Vitamin name="Hloridi" checked={nutricalRequest?.nutrient_flag?.chloride}
                         onChange={() => handleChange("chloride")}/>

                <Vitamin name="Kalcijum" checked={nutricalRequest?.nutrient_flag?.calcium}
                         onChange={() => handleChange("calcium")}/>

                <Vitamin name="Fosfor" checked={nutricalRequest?.nutrient_flag?.phosphorus}
                         onChange={() => handleChange("phosphorus")}/>

                <Vitamin name="Magnezijum" checked={nutricalRequest?.nutrient_flag?.magnesium}
                         onChange={() => handleChange("magnesium")}/>

                <Vitamin name="Gvožđe" checked={nutricalRequest?.nutrient_flag?.iron}
                         onChange={() => handleChange("iron")}/>

                <Vitamin name="Cink" checked={nutricalRequest?.nutrient_flag?.zinc}
                         onChange={() => handleChange("zinc")}/>

                <Vitamin name="Bakar" checked={nutricalRequest?.nutrient_flag?.copper}
                         onChange={() => handleChange("copper")}/>

                <Vitamin name="Mangan" checked={nutricalRequest?.nutrient_flag?.manganese}
                         onChange={() => handleChange("manganese")}/>

                <Vitamin name="Fluorid" checked={nutricalRequest?.nutrient_flag?.fluoride}
                         onChange={() => handleChange("fluoride")}/>

                <Vitamin name="Selen" checked={nutricalRequest?.nutrient_flag?.selenium}
                         onChange={() => handleChange("selenium")}/>

                <Vitamin name="Hrom" checked={nutricalRequest?.nutrient_flag?.chromium}
                         onChange={() => handleChange("chromium")}/>

                <Vitamin name="Molibden" checked={nutricalRequest?.nutrient_flag?.molybdenum}
                         onChange={() => handleChange("molybdenum")}/>

                <Vitamin name="Jod" checked={nutricalRequest?.nutrient_flag?.iodine}
                         onChange={() => handleChange("iodine")}/>

            </div>

            <div className="flex justify-between mt-12">
                <Button color={"transparent"}
                        size="large"
                        startIcon={<ArrowLeftIcon/>} onClick={onBack}>Nazad</Button>
                <Button className="ml-15"
                        size="large"
                        color={"orange"}
                        endIcon={<ArrowRightIcon/>}
                        onClick={onNext}
                        disabled={!isValid}>Sledeći korak</Button>
            </div>

        </div>
    );
}

export default VitaminsForm