import React, {useContext} from "react"
import Modal from "./Modal";
import Button from "./Button";
import {PACKAGE_ADDITION_PRICES} from "../config/config";
import {PackageAdditionPrice} from "../form/IngredientsForm";
import {NutricalRequestContext} from "../form/Form";
import {getSubscriptionType, SubscriptionType} from "./SubscriptionSelect";
import {usePriceCalc} from "../hooks/usePriceCalc";

const Item = () => {

}

export const PackageItem = ({display, onChange, label}) => {

    if (!display) {
        return
    }

    return (
        <div className="flex items-baseline mt-4" onClick={() => {}}>
            <img src="/icons/checkmark-black.svg" alt=""/>
            <div className="ml-4 text-sm text-black font-medium">{label}</div>
        </div>
    )
}



const SubscriptionDetailsModal = ({isOpen, subscriptionType, onClose}) => {

    const {nutricalRequest, setNutricalRequest} = useContext(NutricalRequestContext)
    const totalPrice = usePriceCalc()

    if (!isOpen) {
        return
    }

    const onNutrientFlagToggle = (name) => {
        setNutricalRequest({
            ...nutricalRequest,
            nutrient_flag: {
                ...nutricalRequest.nutrient_flag,
                [name]: !nutricalRequest.nutrient_flag[name]
            }
        })
    }

    return (
        <Modal contentClassName="w-[650px] p-4 sm:p-8" onClose={onClose}>

            <div className="text-center text-[34px] mt-30">Detalji paketa</div>


            <div className="flex justify-between text-sm font-medium mt-8">
                <div className="flex items-baseline">
                    {/*<CheckIcon/>*/}
                    <img src="/icons/checkmark-black.svg" alt=""/>
                    <div className="ml-4">{subscriptionType.label}</div>
                </div>
                <div>{subscriptionType.price} RSD</div>
            </div>

            <div className="overflow-auto">

                {
                    subscriptionType.name === SubscriptionType.BASIC.name && (
                        <div>

                            <PackageItem label={
                                <div className="w-full">
                                        <span>Izrazi energetsku vrednost i količinu hranjivih materija po
                                        porciji/potrošačkoj jedinici hrane</span>
                                    <PackageAdditionPrice price={PACKAGE_ADDITION_PRICES.referent_entry_percentage}/>
                                </div>
                            }
                                         display={nutricalRequest?.nutrient_flag?.referent_entry_percentage}
                                         onChange={() => onNutrientFlagToggle("referent_entry_percentage")}/>

                            <PackageItem label={
                                <div className="w-full">
                                                    <span>Izrazi procenat referentnog unosa (% RU) za 100g, po porciji/potrošačkoj
                                                    jedinici</span>
                                    <PackageAdditionPrice price={PACKAGE_ADDITION_PRICES.portion}/>
                                </div>
                            }
                                         display={nutricalRequest?.nutrient_flag?.portion}
                                         onChange={() => onNutrientFlagToggle("portion")}/>

                            <PackageItem label={
                                <div className="w-full">
                                    <span>Prikazati mononezasićene masne kiseline</span>
                                    <PackageAdditionPrice price={PACKAGE_ADDITION_PRICES.mono_unsaturated}/>
                                </div>
                            }
                                         display={nutricalRequest?.nutrient_flag?.mono_unsaturated}
                                         onChange={() => onNutrientFlagToggle("mono_unsaturated")}/>

                            <PackageItem
                                label={
                                    <div className="w-full">
                                        <span>Prikazati polinezasićene masne kiseline</span>
                                        <PackageAdditionPrice price={PACKAGE_ADDITION_PRICES.poly_unsaturated}/>
                                    </div>
                                }
                                display={nutricalRequest?.nutrient_flag?.poly_unsaturated}
                                onChange={() => onNutrientFlagToggle("poly_unsaturated")}/>

                            <PackageItem
                                label={
                                    <div className="w-full">
                                        <span>Prikazati poliole</span>
                                        <PackageAdditionPrice price={PACKAGE_ADDITION_PRICES.polyol}/>
                                    </div>
                                }
                                display={nutricalRequest?.nutrient_flag?.polyol}
                                onChange={() => onNutrientFlagToggle("polyol")}/>

                            <PackageItem
                                label={
                                    <div className="w-full">
                                        <span>Prikazati vlakna</span>
                                        <PackageAdditionPrice price={PACKAGE_ADDITION_PRICES.fibre}/>
                                    </div>
                                }
                                display={nutricalRequest?.nutrient_flag?.fibre}
                                onChange={() => onNutrientFlagToggle("fibre")}/>

                            <PackageItem
                                label={
                                    <div className="w-full">
                                        <span>Prikazati skrob</span>
                                        <PackageAdditionPrice price={PACKAGE_ADDITION_PRICES.starch}/>
                                    </div>
                                }
                                display={nutricalRequest?.nutrient_flag?.starch}
                                onChange={() => onNutrientFlagToggle("starch")}/>
                        </div>
                    )
                }
            </div>

                <div className="flex justify-between bg-gray-200 rounded-md p-[10px] mt-4 font-medium">
                    <div>Total:</div>
                    <div>{totalPrice} RSD</div>
                </div>

                <div className="flex justify-center mt-4">
                    <Button color="orange"
                            size="large"
                            onClick={onClose}>U redu</Button>
                </div>

        </Modal>
    )

}

export default SubscriptionDetailsModal