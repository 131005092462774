import React from "react";

import "../css/modal.css"
import classNames from "classnames";
import {XIcon} from "./Icons";

const Modal = (props) => {

    const {hide, contentClassName, onClose} = props

    if (hide) {
        return
    }

    const handleOnCloseBtnClick = () => {
        onClose()
    }

    const contentClasses = classNames(contentClassName, "content max-w-[95%] sm:max-w-[80%] max-h-[95vh] sm:max-h-[80vh]")

    return (
        <div className={"Modal flex flex-col"}>
            <div className={contentClasses}>
                <div className="modal-close-btn" onClick={handleOnCloseBtnClick}><XIcon/></div>
                {props.children}
            </div>
        </div>
    )
}

Modal.Header = (props) => {

    return (
        <div className="header">
            {props.children}
        </div>
    )
}

Modal.Body = (props) => {
    return (
        <div className="body overflow-auto">
            {props.children}
        </div>
    )
}

Modal.Footer = (props) => {

    const {isValid} = props
    const {className} = props

    const classes = classNames(className, "footer")

    return (
        <div className={classes}>
            {props.children}
            {!isValid &&  <div className="invalid"></div>}
        </div>
    )
}

export default Modal