import React, {useContext, useState} from "react";

import Button from "../components/Button";
import {NutricalRequestContext} from "./Form";
import TextField from "../components/TextField";
import "../css/ingredientForm.css"
import UnitSelect, {Unit} from "../components/UnitSelect";
import FileUpload from "../components/FileUpload";
import TextAreaField from "../components/TextAreaField";
import NumberField from "../components/NumberField";
import Tooltip from "../components/Tooltip";
import {ArrowLeftIcon, ArrowRightIcon, PlusCircleIcon} from "../components/Icons";
import Checkbox from "../components/Checkbox";
import {business_policy_url, cookies_policy_url, PACKAGE_ADDITION_PRICES, terms_policy_url} from "../config/config";
import Checkmark from "../components/Checkmark";
import RadioBtnMark from "../components/RadioBtnMark";
import {getSubscriptionType, SubscriptionType} from "../components/SubscriptionSelect";

const Switch = ({label, value, onChange}) => {

    return (
        <div className="mt-6 flex justify-between items-center mb-4">
            <div className="font-medium">{label}</div>
            <div className="flex items-center">
                <div className="flex items-center ml-6 hover:cursor-pointer"
                     onClick={() => onChange(true)}>
                    <RadioBtnMark selected={value}/>
                    <div className="ml-2">Da</div>
                </div>
                <div className="flex items-center ml-6 hover:cursor-pointer"
                     onClick={() => onChange(false)}>
                    <RadioBtnMark selected={!value}/>
                    <div className="ml-2">Ne</div>
                </div>
            </div>
        </div>
    )
}

export const NutrientExtendedCheckbox = ({checked, onChange, label}) => {

    return (
        <div className="flex items-center mt-3 hover:cursor-pointer hover:opacity-80" onClick={onChange}>
            <Checkmark checked={checked}/>
            <div className="ml-2 text-sm">{label}</div>
        </div>
    )
}

export const PackageAdditionPrice = ({price}) => {

    const {nutricalRequest} = useContext(NutricalRequestContext)

    const subscriptionType = getSubscriptionType(nutricalRequest.subscription_package)

    if (subscriptionType.name !== SubscriptionType.BASIC.name) {
        return
    }

    const formatted = Number.parseFloat(price).toFixed(2).replace('.', ",")


    return (
        <span className="ml-1 text-orange-600 nowrap">
            (Cena {formatted} RSD)
        </span>
    )
}

const IngredientsForm = ({hide, onNext, onBack, stepIndex}) => {

    const {nutricalRequest, setNutricalRequest} = useContext(NutricalRequestContext)
    const [displayInfo, setDisplayInfo] = useState(true)
    const [showNutrientExtended, setShowNutrientExtended] = useState(false)

    if (hide) {
        return
    }

    const onPreparationMethodChange = (e) => {
        setNutricalRequest({
            ...nutricalRequest,
            recipe_method_preparation: e.target.value
        })
    }

    const onIngredientChange = (index, field, value) => {

        let ingredientsLocal = [...nutricalRequest.ingredients]
        ingredientsLocal[index][field] = value

        setNutricalRequest({
            ...nutricalRequest,
            ingredients: ingredientsLocal
        })
    }

    const onAmountChange = (e) => {
        setNutricalRequest({
            ...nutricalRequest,
            recipe_prepared_amount: e.target.value
        })
    }

    const onRecipeUnitChange = (e) => {
        setNutricalRequest({
            ...nutricalRequest,
            recipe_unit: e.target.value
        })
    }

    const onAddIngredientBtnClick = () => {
        if (nutricalRequest.ingredients.length > 15) {
            return
        }

        let ingredientsLocal = [...nutricalRequest.ingredients]
        ingredientsLocal.push({
            unit: Unit.G
        })

        setNutricalRequest({
            ...nutricalRequest,
            ingredients: ingredientsLocal
        })
    }

    const onRemoveIngredientBtnClick = (index) => {
        let ingredientsLocal = [...nutricalRequest.ingredients]
        ingredientsLocal.splice(index, 1)

        setNutricalRequest({
            ...nutricalRequest,
            ingredients: ingredientsLocal
        })
    }

    const onREFlagChange = (e) => {
        setNutricalRequest({
            ...nutricalRequest,
            nutrient_flag: {
                ...nutricalRequest.nutrient_flag,
                "referent_entry_percentage": e.target.checked
            }
        })
    }

    const onPortionFlagChange = (e) => {
        setNutricalRequest({
            ...nutricalRequest,
            nutrient_flag: {
                ...nutricalRequest.nutrient_flag,
                "portion": e.target.checked
            }
        })
    }

    const onPortionAmountChange = (e) => {
        setNutricalRequest({
            ...nutricalRequest,
            portion_amount: e.target.value
        })
    }

    const onPortionUnitChange = (e) => {
        setNutricalRequest({
            ...nutricalRequest,
            portion_unit: e.target.value
        })
    }

    const onNutrientFlagToggle = (name) => {
        setNutricalRequest({
            ...nutricalRequest,
            nutrient_flag: {
                ...nutricalRequest.nutrient_flag,
                [name]: !nutricalRequest.nutrient_flag[name]
            }
        })
    }

    const handleShowNutrientExtendedChange = (value) => {

        if (!value) {
            setNutricalRequest({
                ...nutricalRequest,
                nutrient_flag: {
                    ...nutricalRequest.nutrient_flag,
                    "mono_unsaturated": false,
                    "poly_unsaturated": false,
                    "polyol": false,
                    "fibre": false,
                    "starch": false,
                }
            })
        }

        setShowNutrientExtended(value)
    }

    const validateForm = () => {

        let validation = {
            isValid: true,
            ingredients: {
                errorMsg: ""
            },
            portion_amount: {
                errorMsg: ""
            }

        }

        let hasInvalidIngredient = [...nutricalRequest.ingredients].filter(e => {
            if (!e.name && !e.amount && !e.description) {
                return false
            }

            if (e.name && e.amount) {
                return false
            }

            return true

        }).length > 0


        if (hasInvalidIngredient) {
            validation.isValid = false
        }

        if (nutricalRequest.nutrient_flag.portion && !nutricalRequest.portion_amount) {
            validation.isValid = false
            validation.portion_amount.errorMsg = "Obavezno polje"
        }

        return validation
    }

    const validation = validateForm()
    const isValid = validation.isValid

    const isSmallScreen = window.innerWidth < 768;



    return (
        <div className="IngredientForm">

            <div className="section-id">
                <span>{stepIndex}</span>
                <span>Sastav proizvoda i način pripreme</span>
            </div>

            {/*{*/}
            {/*    displayInfo &&*/}
            {/*    <div className="flex bg-white p-6 rounded-lg mt-30 mt-8 items-center info-box-shadow">*/}
            {/*        <div className="mr-8 text-sm">*/}
            {/*            Sve informacije koje unesete u ovaj obrazac podležu našim*/}
            {/*            <a href={terms_policy_url} target="_blank" className="font-semibold underline hover:text-text-highlight"> Uslovima poslovanja</a>,*/}
            {/*            <a href={business_policy_url} target="_blank" className="font-semibold underline hover:text-text-highlight"> Politici privatnosti</a> i*/}
            {/*            <a href={cookies_policy_url} target="_blank" className="font-semibold underline hover:text-text-highlight"> Politici upotrebe kolačića.</a>*/}
            {/*            Nakon što popunite i pošaljete ovaj zahtev, dobićete e-mail koji potvrđuje da je zahtev primljen.*/}
            {/*            Nakon pregleda vašeg zahteva, ukoliko je zahtev kompletan, dobićete e-mail koja sadrži detalje*/}
            {/*            vezane za plaćanje usluge.*/}
            {/*            Ukoliko su nam potrebne dodatne informacije, kontaktiraćemo vas.*/}
            {/*        </div>*/}
            {/*        <Button onClick={() => setDisplayInfo(false)} className="" size="large" color="neutral">U redu</Button>*/}
            {/*    </div>*/}
            {/*}*/}


            <div className="section mt-8">
                {/*<div>*/}
                {/*    Unesite podatke o proizvodu popunjavanjem sekcija Sastojci i Način pripreme ili jednostavno*/}
                {/*    priložite dokumentaciju o proizvodu u sekciji Prilozi*/}
                {/*</div>*/}
                {/*<Tooltip className="mt-8 mb-2" title="Sastojci">*/}
                {/*    Navedite sve sastojke vašeg proizvoda, uključujići i složene sastojke, dodatu vodu, aditive, arome i*/}
                {/*    sl. Prilikom navođenja sastojaka proizvoda, budite veoma određeni, npr. kakao prah sa sadržajem*/}
                {/*    masti 10-12%, pšenično belo brašno tip 500, polumasni polutvrdi sir sa 45% m.m. u s.m. Ukoliko vaš*/}
                {/*    recept sadrži složeni sastojak (sastojak koji je proizveden od dva ili više sastojaka) molimo vas da*/}
                {/*    podatke o njegovom sastavu i nutritivnu tablicu priložite u polju <b>“Prilozi”</b>.*/}
                {/*</Tooltip>*/}
                <table className="table-fixed">
                    <thead>
                    <tr>
                        <th className="text-left">Naziv sastojka</th>
                        <th className="w-[60px] md:w-[120px] nowrap text-center">Kolicina</th>
                        <th className="w-[80px] md:w-[180px]">Jedinica</th>
                        <th className="w-[20px] md:w-[40px]"></th>
                    </tr>

                    </thead>
                    <tbody>
                    {
                        nutricalRequest.ingredients.map((e, index) => (
                            <tr key={index}>
                                <td><TextField value={e.name}
                                               onChange={(e) => onIngredientChange(index, "name", e.target.value)}
                                               placeholder="Unesite precizan naziv sastojka"/>
                                </td>
                                <td><NumberField className="text-center p-0"
                                                 isDecimal={true}
                                                 value={e.amount || ''}
                                                 onChange={(e) => onIngredientChange(index, "amount", e.target.value)}
                                                 placeholder="0"/>
                                </td>
                                <td><UnitSelect value={e.unit}
                                                onChange={(e) => onIngredientChange(index, "unit", e.target.value)}/>
                                </td>
                                <td>
                                    <div onClick={() => onRemoveIngredientBtnClick(index)} className="delete-action">
                                        <img className="w-[16px] lg:w-[24px]" alt=""
                                             src="/icons/delete-black.svg"/></div>
                                </td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
                <div>
                    <Button onClick={onAddIngredientBtnClick}
                            color="secondary"
                            size="large"
                            startIcon={<PlusCircleIcon/>}>Još sastojaka</Button>
                </div>

                <Tooltip className="mt-8 mb-2" title="Način pripreme">
                    Navedite ukratko način pripreme proizvoda. Obavezno navesti sve termičke tretmane, npr. <b>kuvanje,
                    pečenje, prženje u ulju, prženje u dubokom ulju...</b>
                </Tooltip>

                <TextAreaField value={nutricalRequest.recipe_method_preparation}
                               onChange={onPreparationMethodChange}
                               placeholder="Unesite način pripreme i druge informacije od znacaja ..."
                               rows={7}></TextAreaField>


                <Tooltip className="mt-8 mb-2" title="Prilozi">
                    Priložite podatke o proizvodu ili složenim sastojcima, npr. proizvodjacku specifikaciju, fotografiju
                    deklaracije slozenog sastojka, fotografiju proizvoda ili bilo koje druge podatke koji mogu biti od
                    značaja za postupak izračunavanja
                </Tooltip>
                <FileUpload nutricalRequest={nutricalRequest} setNutricalRequest={setNutricalRequest}/>


                <div className="mt-8 flex center">
                    <div className="xl:flex gap-x-4 items-center">
                        <Tooltip className="" title="Masa proizvoda nakon pripreme">
                            Navedite finalnu masu gotovog proizvoda nakon pripreme koja se dobija od kolicine gore
                            navedenih sastojaka
                        </Tooltip>
                        <div className="flex items-center">
                            <NumberField className="w-[80px] md:w-[130px] text-center"
                                         value={nutricalRequest.recipe_prepared_amount || ''}
                                         onChange={onAmountChange}
                                         isDecimal={true}
                                         mandatory={false}
                                         placeholder="0"/>
                            {/*<Tooltip className="ml-4 md:ml-12" title="Jedinica"/>*/}
                            <UnitSelect className="min-w-[80px] max-w-[130px] ml-2"
                                        value={nutricalRequest.recipe_unit}
                                        onChange={onRecipeUnitChange}>
                                <option value={Unit.G}>{isSmallScreen ? 'g' : 'Gram (g)'}</option>
                                <option value={Unit.ML}>{isSmallScreen ? 'ml' : 'Mililitar (ml)'}</option>
                            </UnitSelect>
                        </div>
                    </div>
                </div>

                <div className="font-medium mt-8 text-md">
                    Nutritivna tabela u izveštaju sadržati informacije o energetskoj vrednosti i količini hranjivih
                    materija na 100g/ml proizvoda
                </div>

                <div className="flex flex-col mt-4 md:mt-4">
                    <div className="text-orange-600 font-medium text-sm">Pored obaveznih elemenata nutritivne deklaracije, koji su u sklopu BASIC PAKETA, dodatno možete izabrati:</div>
                    <div className="flex items-center mt-2 md:mt-6">
                        <Checkbox className=""
                                  onChange={onREFlagChange}
                                  checked={nutricalRequest?.nutrient_flag?.referent_entry_percentage}>
                            <div className="items-baseline text-sm pl-3">
                                <span>Izrazi procenat referentnog unosa (% RU) za 100g, po porciji/potrošačkoj</span><span
                                className="nowrap"> jedinici
                                    <PackageAdditionPrice price={PACKAGE_ADDITION_PRICES.referent_entry_percentage}/>
                                </span>
                            </div>
                        </Checkbox>
                    </div>
                    <div className="flex mt-2 md:mt-6 items-center ">
                        <Checkbox className="" onChange={onPortionFlagChange}
                                  checked={nutricalRequest?.nutrient_flag?.portion}>
                            <div className="items-baseline text-sm pl-3">
                                <span>Izrazi energetsku vrednost i količinu hranjivih materija po porciji/potrošačkoj jedinici</span><span
                                className="nowrap"> hrane

                                <PackageAdditionPrice price={PACKAGE_ADDITION_PRICES.portion}/>
                                </span>
                            </div>
                        </Checkbox>
                    </div>
                </div>

                <div className="flex  mt-8 items-center gap-x-8">
                    <div className="flex justify-between lg:justify-start items-center max-w-[400px] grow">
                        <Tooltip className="" title="Veličina porcije"/>
                        <div className="flex items-center">
                            <NumberField className="w-[80px] ml-2 text-center"
                                         value={nutricalRequest.portion_amount || ''}
                                         onChange={onPortionAmountChange}
                                         isDecimal={true}
                                         mandatory={false}
                                         errorMsg={validation.portion_amount.errorMsg}
                                         placeholder="0"/>
                            <UnitSelect className="min-w-[80px] max-w-[125px] ml-2"
                                        value={nutricalRequest.portion_unit}
                                        onChange={onPortionUnitChange}>
                                <option value={Unit.G}>{isSmallScreen ? 'g' : 'Gram (g)'}</option>
                                <option value={Unit.ML}>{isSmallScreen ? 'ml' : 'Mililitar (ml)'}</option>
                            </UnitSelect>
                        </div>
                    </div>

                    {/*<div className="flex justify-between lg:justify-start items-center">*/}
                    {/*    <Tooltip className="" title="Jedinica"/>*/}
                    {/*    <UnitSelect className="w-[120px]"*/}
                    {/*                value={nutricalRequest.portion_unit}*/}
                    {/*                onChange={onPortionUnitChange}>*/}
                    {/*        <option value={Unit.G}>Gram (g)</option>*/}
                    {/*        <option value={Unit.ML}>Mililitar (ml)</option>*/}
                    {/*    </UnitSelect>*/}
                    {/*</div>*/}
                </div>

                <div className="max-w-[500px]">
                    <Switch label="Proširena nutritivna tabela"
                            value={showNutrientExtended}
                            onChange={handleShowNutrientExtendedChange}/>

                    {
                        showNutrientExtended &&
                        <div>
                            <NutrientExtendedCheckbox label={
                                                            <div className="w-full">
                                                                <span>Prikazati mononezasićene masne kiseline</span>
                                                                <PackageAdditionPrice price={PACKAGE_ADDITION_PRICES.mono_unsaturated}/>
                                                            </div>
                                                      }
                                                      checked={nutricalRequest?.nutrient_flag?.mono_unsaturated}
                                                      onChange={() => onNutrientFlagToggle("mono_unsaturated")}/>

                            <NutrientExtendedCheckbox
                                                      label={
                                                          <div className="w-full">
                                                              <span>Prikazati polinezasićene masne kiseline</span>
                                                              <PackageAdditionPrice price={PACKAGE_ADDITION_PRICES.poly_unsaturated}/>
                                                          </div>
                                                      }
                                                      checked={nutricalRequest?.nutrient_flag?.poly_unsaturated}
                                                      onChange={() => onNutrientFlagToggle("poly_unsaturated")}/>

                            <NutrientExtendedCheckbox
                                                      label={
                                                          <div className="w-full">
                                                              <span>Prikazati poliole</span>
                                                              <PackageAdditionPrice price={PACKAGE_ADDITION_PRICES.polyol}/>
                                                          </div>
                                                      }
                                                      checked={nutricalRequest?.nutrient_flag?.polyol}
                                                      onChange={() => onNutrientFlagToggle("polyol")}/>

                            <NutrientExtendedCheckbox
                                                      label={
                                                          <div className="w-full">
                                                              <span>Prikazati vlakna</span>
                                                              <PackageAdditionPrice price={PACKAGE_ADDITION_PRICES.fibre}/>
                                                          </div>
                                                      }
                                                      checked={nutricalRequest?.nutrient_flag?.fibre}
                                                      onChange={() => onNutrientFlagToggle("fibre")}/>

                            <NutrientExtendedCheckbox
                                                      label={
                                                          <div className="w-full">
                                                              <span>Prikazati skrob</span>
                                                              <PackageAdditionPrice price={PACKAGE_ADDITION_PRICES.starch}/>
                                                          </div>
                                                      }
                                                      checked={nutricalRequest?.nutrient_flag?.starch}
                                                      onChange={() => onNutrientFlagToggle("starch")}/>
                        </div>
                    }

                    {nutricalRequest?.subscription_package === SubscriptionType.PREMIUM.name &&
                        <>
                            <Switch label="Traffic Light score"
                                    value={nutricalRequest?.nutrient_flag?.traffic_light_standard}
                                    onChange={() => onNutrientFlagToggle('traffic_light_standard')}/>

                            <Switch label="Nutritivne izjave"
                                    value={nutricalRequest?.nutrient_flag?.nutrient_statement}
                                    onChange={() => onNutrientFlagToggle('nutrient_statement')}/>
                        </>
                    }
                </div>


            </div>


            <div className="flex justify-between mt-12">
                <Button color={"transparent"}
                        size="large"
                        startIcon={<ArrowLeftIcon/>} onClick={onBack}>Nazad</Button>
                <Button className="ml-15"
                        size="large"
                        color={"orange"}
                        endIcon={<ArrowRightIcon/>}
                        onClick={onNext}
                        disabled={!isValid}>Sledeći korak</Button>
            </div>
        </div>
    )
}

export default IngredientsForm