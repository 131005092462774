export const NUMBER_REGEX = "^[0-9]*$"
export const DECIMAL_NUMBER_REGEX = "^(?![.])(?!0[0-9])([0-9]*[.])?[0-9]*$"

export const API_BASE = "https://api-dev.nutrikator.com/v1"

export const DIGITAL_MENU_API = API_BASE + "/digital-menu/restaurants"

export const NUTRICAL_REQUEST_API = API_BASE + "/nutrical-requests"
export const MEDIA_API = API_BASE + "/media"

export const terms_policy_url = "https://nutrikator.com/uslovi-poslovanja"
export const business_policy_url = "https://nutrikator.com/politika-privatnosti"
export const cookies_policy_url = "https://nutrikator.com/politika-upotrebe-kolacica"


export const PACKAGE_ADDITION_PRICES = {
    referent_entry_percentage: 600,
    portion: 400,
    mono_unsaturated: 200,
    poly_unsaturated: 200,
    polyol: 200,
    fibre: 200,
    starch: 200
}
