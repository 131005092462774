import {getSubscriptionType, SubscriptionType} from "../components/SubscriptionSelect";
import {PACKAGE_ADDITION_PRICES} from "../config/config";
import {useContext} from "react";
import {NutricalRequestContext} from "../form/Form";

export const usePriceCalc = () => {

    const {nutricalRequest} = useContext(NutricalRequestContext)

    const subscriptionType = getSubscriptionType(nutricalRequest.subscription_package)

    let basePrice = Number(subscriptionType.price.replace(".", ""))

    if (subscriptionType.name !== SubscriptionType.BASIC.name) {
        return basePrice
    }

    return [
        nutricalRequest?.nutrient_flag?.referent_entry_percentage ?
            PACKAGE_ADDITION_PRICES.referent_entry_percentage : 0,
        nutricalRequest?.nutrient_flag?.portion ?
            PACKAGE_ADDITION_PRICES.portion : 0,
        nutricalRequest?.nutrient_flag?.mono_unsaturated ?
            PACKAGE_ADDITION_PRICES.mono_unsaturated : 0,
        nutricalRequest?.nutrient_flag?.poly_unsaturated ?
            PACKAGE_ADDITION_PRICES.poly_unsaturated : 0,
        nutricalRequest?.nutrient_flag?.polyol ?
            PACKAGE_ADDITION_PRICES.polyol : 0,
        nutricalRequest?.nutrient_flag?.fibre ?
            PACKAGE_ADDITION_PRICES.fibre : 0,
        nutricalRequest?.nutrient_flag?.starch ?
            PACKAGE_ADDITION_PRICES.starch : 0,
    ].map(price => Number(price))
        .reduce((acc, num) => acc + num, basePrice)
}