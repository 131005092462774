import React, {useContext, useState} from "react";

import Button from "../components/Button";
import TextField from "../components/TextField";
import {NutricalRequestContext} from "./Form";
import "../css/companyForm.css"
import Tooltip from "../components/Tooltip";
import {ArrowLeftIcon, SendIcon} from "../components/Icons";
import Checkbox from "../components/Checkbox";
import {getSubscriptionType} from "../components/SubscriptionSelect";
import SubscriptionDetailsModal from "../components/SubscriptionDetailsModal";
import {business_policy_url, terms_policy_url} from "../config/config";
import {usePriceCalc} from "../hooks/usePriceCalc";

const CompanyForm = ({hide, onNext, onBack, stepIndex}) => {

    const {nutricalRequest, setNutricalRequest} = useContext(NutricalRequestContext)
    const [displaySubscModal, setDisplaySubscModal] = useState(false)
    const [agreedToTerms, setAgreedToTerms] = useState(false)
    const totalPrice = usePriceCalc()

    if (hide) {
        return
    }

    const handleValueChange = (fieldName, value) => {
        setNutricalRequest({
            ...nutricalRequest,
            [fieldName]: value
        })
    }

    const isValidEmail = (email) => {
        if (!email) {
            return true;
        }

        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    };

    const validateForm = () => {

        let validation = {
            isValid: true,
            customer_name: {
                errorMsg: ""
            },
            customer_email: {
                errorMsg: ""
            }

        }

        if (!agreedToTerms) {
            validation.isValid = false
        }

        if (!nutricalRequest.customer_name) {
            validation.isValid = false
        }

        if (nutricalRequest.customer_name && nutricalRequest.customer_name.length < 3) {
            validation.customer_name.errorMsg = "Minimalan broj karaktera: 3"
            validation.isValid = false
        }

        if (!nutricalRequest.customer_phone ||
            !nutricalRequest.customer_city ||
            !nutricalRequest.contact_name ||
            !nutricalRequest.customer_email) {
            validation.isValid = false
        }

        if (!isValidEmail(nutricalRequest.customer_email)) {
            validation.customer_email.errorMsg = "Unesite ispravan format"
            validation.isValid = false
        }


        return validation
    }

    const validation = validateForm()
    const isValid = validation.isValid

    const subscriptionType = getSubscriptionType(nutricalRequest.subscription_package)

    return (
        <div className="CompanyForm">

            <div className="flex flex-wrap lg:flex-nowrap justify-between items-center gap-4">
                <div className="section-id">
                    <span>{stepIndex}</span>
                    <span className="nowrap">Vaši podaci</span>
                </div>
                <div className="flex justify-between w-full bg-white rounded-md px-[15px] py-[10px] text-sm items-center">
                    <div className="flex items-center">
                        <div>Paket:</div>
                        <div className="ml-6 font-medium">{subscriptionType.label}</div>
                        <div className="ml-6 font-medium nowrap">{totalPrice} RSD</div>
                    </div>
                    <div className="font-medium underline cursor-pointer"
                         onClick={() => setDisplaySubscModal(true)}>Detaljnije</div>
                </div>
            </div>

            <div className="flex mt-40 section flex-col">
                <Tooltip title="Naziv firme ili fizičkog lica koje podnosi zahtev" className="mb-2"/>
                <TextField placeholder="Unesite ime firme ili vaše ime"
                           id="name"
                           value={nutricalRequest.customer_name}
                           onChange={(e) => handleValueChange("customer_name", e.target.value)}
                           mandatory={true}
                           errorMsg={validation.customer_name.errorMsg}/>
                <div className="flex flex-wrap gap-x-8">
                    <div className="grow basis-3">
                        <Tooltip title="Adresa" className="mb-2 mt-8" isOptional={true}/>
                        <TextField
                            placeholder="Unesite adresu sedišta firme ili vašu adresu"
                            id="address"
                            value={nutricalRequest.customer_address}
                            onChange={(e) => handleValueChange("customer_address", e.target.value)}
                            mandatory={true}/>

                        <Tooltip title="Email adresa" className="mb-2 mt-8"/>
                        <TextField className=""
                                   placeholder="Unesite email"
                                   id="email"
                                   value={nutricalRequest.customer_email}
                                   onChange={(e) => handleValueChange("customer_email", e.target.value)}
                                   mandatory={true}
                                   errorMsg={validation.customer_email.errorMsg}/>

                        <Tooltip title="Osoba za kontakt" className="mb-2 mt-8"/>
                        <TextField className=""
                                   placeholder="Unesite ime osobe za kontakt"
                                   id="contactPerson"
                                   value={nutricalRequest.contact_name}
                                   onChange={(e) => handleValueChange("contact_name", e.target.value)}
                                   mandatory={true}/>

                    </div>

                    <div className="grow  basis-3">
                        <Tooltip title="Grad" className="mb-2 mt-8"/>
                        <TextField className=""
                                   placeholder="Unesite naziv grada"
                                   id="customerCIty"
                                   value={nutricalRequest.customer_city}
                                   onChange={(e) => handleValueChange("customer_city", e.target.value)}
                                   mandatory={true}/>

                        <Tooltip title="Broj telefona" className="mb-2 mt-8"/>
                        <TextField className=""
                                   placeholder="Unesite broj telefona u formatu +381XXXXXXXX"
                                   id="phone"
                                   value={nutricalRequest.customer_phone}
                                   onChange={(e) => handleValueChange("customer_phone", e.target.value)}
                                   mandatory={true}/>


                        <Tooltip title="PIB" className="mb-2 mt-8" mandatory={false} isOptional={true}/>
                        <TextField className=""
                                   placeholder="Unesite poreski identifikacioni broj"
                                   id="taxId"
                                   value={nutricalRequest.customer_vat_number}
                                   onChange={(e) => handleValueChange("customer_vat_number", e.target.value)}
                                   mandatory={false}/>


                    </div>

                </div>
                <div className="flex mt-8">
                    <Checkbox checked={agreedToTerms} onChange={(e) => setAgreedToTerms(e.target.checked)}>

                    </Checkbox>
                    <div className="ml-4 text-[16px]">
                        Slazem se sa
                        <a href={terms_policy_url} target="_blank" className="font-semibold underline hover:text-text-highlight"> Uslovima poslovanja</a> i
                        <a href={business_policy_url} target="_blank" className="font-semibold underline hover:text-text-highlight"> Politikom privatnosti.</a>
                    </div>
                </div>
            </div>

            <div className="flex justify-between mt-12">
                <Button color={"transparent"}
                        size="large"
                        startIcon={<ArrowLeftIcon/>} onClick={onBack}>Nazad</Button>

                <Button className="ml-15"
                        size="large"
                        color={"green"}
                        startIcon={<SendIcon/>}
                        onClick={onNext}
                        disabled={!isValid}>Pošalji zahtev</Button>
            </div>

            <SubscriptionDetailsModal isOpen={displaySubscModal}
                                      onClose={() => setDisplaySubscModal(false)}
                                      subscriptionType={subscriptionType}/>
        </div>
    )
}

export default CompanyForm